import axios, { AxiosError } from 'axios';
import $isUserLogin from '../recoil/atom/login/is-user-login';
import { API_URL } from '../service/config';
import AuthManager from './auth-manager';
import UserManager from './user-manager';
import { errorParser } from './function';
import StorageManager from './storage-manager';

interface RefreshRequestModel {
    refreshToken: string;
}

interface RefreshResponseModel extends RefreshRequestModel {
    accessToken: string;
}

export default function onErrorAccessTokenRefresh(err: Error | AxiosError, callback: () => void) {
    const am = new AuthManager();
    const um = new UserManager();
    const sm = new StorageManager();
    const errorData = errorParser(err);

    if (errorData.msg === 'Token Has Expired' && errorData.code === 401) {
        if (sm.get('refreshing').getValue() !== null) {
            setTimeout(() => {
                if (am.isLogin()) {
                    callback();
                }
            }, 500);

            return;
        }

        sm.set('refreshing', 1);

        axios({
            url: `${API_URL}/auth/refresh`,
            method: 'POST',
            data: {
                refreshToken: new AuthManager().getRefreshToken() ?? '',
            },
        })
            .then((res) => {
                const data = res.data.data as RefreshResponseModel;

                am.setAccessToken(data.accessToken);
                am.setRefreshToken(data.refreshToken);

                callback();
            })
            .catch(() => {
                const am = new AuthManager();
                const um = new UserManager();

                if (am.isLogin()) {
                    am.logout();
                    window.location.replace('/');
                } else if (um.isLogin()) {
                    um.logout();
                    window.location.replace('/');
                }
            })
            .finally(() => {
                sm.remove('refreshing');
            });
    }
}

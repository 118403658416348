/* eslint-disable no-underscore-dangle */
/* eslint-disable prefer-destructuring */
/* eslint-disable no-nested-ternary */
import axios, { AxiosError } from 'axios';
import { AmPm } from '../components/time-picker/time-picker';
import { COMMON_ERROR_MSG, IMAGE_URL } from '../service/config';

// 1000자리수마다 comma 찍기
export const comma = (x: number | string) => {
    const _x = x.toString();

    if (_x.indexOf('.') !== -1) {
        const num = _x.split('.');

        return `${num[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',')}.${num[1]}`;
    }

    return _x.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
};

// 1000자리수마다 comma 찍기 + 소수 1자리
export const commaToFixed = (x: number | string) => {
    const _x = x.toString();

    if (_x.indexOf('.') !== -1) {
        const num = _x.split('.');

        return `${num[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',')}.${num[1].substring(0, 1)}`;
    }

    return _x.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
};

export const isPathName = (path: string) => {
    const pathName = String(window.location.pathname);
    if (pathName === path) {
        return true;
    }
    return false;
};

export const isPathName2 = (path: string) => {
    // eslint-disable-next-line no-underscore-dangle
    const _path = path.replace('/csms', '');

    const pathName = String(window.location.pathname.replace('/csms', '').split('/')[1]);
    if (pathName.includes(_path.split('/')[1])) {
        return true;
    }
    return false;
};

export const isPathName3 = (path: string) => {
    const pathName = String(window.location.pathname);
    if (pathName.includes(path)) {
        return true;
    }
    return false;
};

export interface ErrorModel {
    code: number;
    msg: string;
    isCancel: boolean;
    isAxiosError: boolean;
}

export function errorParser(err: Error | AxiosError): ErrorModel {
    if (axios.isCancel(err)) {
        return {
            code: 499,
            msg: 'canceled',
            isAxiosError: true,
            isCancel: true,
        };
    }
    if (axios.isAxiosError(err)) {
        const msg = err.response?.data?.msg ?? COMMON_ERROR_MSG;
        const code = err.response?.data?.code ?? 400;

        return {
            code,
            msg,
            isAxiosError: true,
            isCancel: false,
        };
    }
    return {
        code: 500,
        msg: COMMON_ERROR_MSG,
        isAxiosError: false,
        isCancel: false,
    };
}

export function numberFormat(e: number): string {
    return e < 10 ? `0${e}` : e.toString();
}

export function passwordKorRemove(password: string): string {
    return password.replace(/[ㄱ-ㅎ | 가-힣]/gi, '');
}

export function hasNumber(e: string): boolean {
    return e.trim().replace(/[^0-9]/gi, '').length > 0;
}

export function hasEng(e: string): boolean {
    return e.trim().replace(/[^a-z | ^A-Z]/gi, '').length > 0;
}

export function hasEtc(e: string): boolean {
    return e.trim().replace(/[a-z | A-Z | 0-9 | ㄱ-ㅎ | 가-힣]/gi, '').length > 0;
}

export function passwordEtcRemove(password: string): string {
    return password.trim().replace(/[^a-z | A-Z | 0-9 | ㄱ-ㅎ | 가-힣]/gi, '');
}

export function passwordEtcRemoveV2(password: string): string {
    return password.trim().replace(/[^a-z | A-Z | 0-9 | ㄱ-ㅎ | 가-힣 | @$!%*#?&]/gi, '');
}

export function passwordRegexp(password: string): boolean {
    return hasNumber(password) && hasEng(password) && hasEtc(password) && password.length >= 8;
}

export function passwordRegexp2(password: string): boolean {
    return hasNumber(password) && hasEng(password) && password.length >= 8 && password.length <= 15;
}

export function qs(selector: string) {
    return document.querySelector(selector) ?? null;
}

export function focus(selector: string) {
    qs(selector)?.focus();
}

export function phoneFormat(e: string) {
    const number = e.replace(/-/gi, '');
    let tel = '';

    if (number.replace(/[ 0-9 | \- ]/g, '').length) {
        return number.substr(0, number.length - 1);
    }

    if (number.length < 4) {
        return number;
    }
    if (number.length < 7) {
        tel += number.substr(0, 3);
        tel += '-';
        tel += number.substr(3);
    } else if (number.length < 11) {
        tel += number.substr(0, 3);
        tel += '-';
        tel += number.substr(3, 3);
        tel += '-';
        tel += number.substr(6);
    } else {
        tel += number.substr(0, 3);
        tel += '-';
        tel += number.substr(3, 4);
        tel += '-';
        tel += number.substr(7);
    }

    return tel;
}

export const modifyPhoneStringForm = (phone: string) => {
    return phone.replace(/[^0-9]/gi, '').replace(/^(\d{2,3})(\d{3,4})(\d{4})$/, `$1-$2-$3`);
};

export const modifyBusinessNumberStringForm = (phone: string) => {
    return phone.replace(/[^0-9]/gi, '').replace(/^(\d{3})(\d{2})(\d{5})$/, `$1-$2-$3`);
};

export const modifyCardNumberForm = (cardNumber: string) => {
    return cardNumber.replace(/[^0-9]/gi, '').replace(/^(\d{4})(\d{4})(\d{4})(\d{4})$/, `$1-$2-$3-$4`);
};

type ObjectType = { [key: string]: any };

export const formGenerator = (body: ObjectType) => {
    const formData = new FormData();

    for (const key of Object.keys(body)) {
        if (body[key] !== null && body[key] !== undefined) {
            formData.append(key, body[key]);
        }
    }

    return formData;
};

export function getPageValue(e: string) {
    return parseInt(e.replace('개씩', ''), 10);
}

export function editorClear() {
    document.querySelectorAll('.cke_wysiwyg_frame').forEach((iframe: Element) => {
        // eslint-disable-next-line no-param-reassign
        iframe!.contentWindow.document.querySelector('body')!.innerText = '';
    });
}

interface GetTableNoProps extends PageableRequestModel {
    index: number;
    total: number;
}

export function getTableNo({ index, total, currentPage, pageSize }: GetTableNoProps) {
    return total === -1 ? '#NAN' : total - index - (currentPage - 1) * pageSize;
}

export function random(min: number, max: number) {
    return Math.floor(Math.random() * (max - min + 1)) + min;
}

export function getRandomColor() {
    const prefix = '#';
    const red = random(50, 200).toString(16);
    const green = random(50, 200).toString(16);
    const blue = random(50, 200).toString(16);

    return `${prefix}${red}${green}${blue}`;
}

export function getNumberForString(e: string) {
    return e.replace(/[^0-9]/g, '');
}

export function nTonn(e: number): string {
    return e < 10 ? `0${e}` : e.toString();
}

export function isFloat(e: number): boolean {
    return e.toString().includes('.');
}

export function isNumber(e: string) {
    return e.replace(/[0-9]/g, '').length === 0;
}

export function safetyNumber(value: number | string | null | undefined) {
    try {
        const targetValue = value?.toString() ?? '0';

        if (!isNumber(targetValue)) {
            throw new Error();
        }
        return parseInt(targetValue, 10);
    } catch {
        return 0;
    }
}

interface GetPeriodXAxisProps {
    type: number;
    count: number;
    year: number;
    month: number;
}

export function getPeriodXAxis({ type, count, year, month }: GetPeriodXAxisProps) {
    const hourXAxis = Array(count)
        .fill(0)
        .map((_, index) => `${index}시`);

    const dayXAxis = Array(count)
        .fill(0)
        .map((_, index) => `${month}-${index + 1}`);

    const monthXAxis = Array(count)
        .fill(0)
        .map((_, index) => `${index + 1}월`);

    const yearXAxis = Array(count)
        .fill(0)
        .map((_, index) => `${year - count + 1 + index}년`);

    return type === 1 ? hourXAxis : type === 2 ? dayXAxis : type === 3 ? monthXAxis : yearXAxis;
}

export function fileToBase64(file: File): Promise<string> {
    return new Promise((resolve) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => {
            resolve(reader.result!.toString());
        };
    });
}

export function getDateDiff(start: string, end: string): string {
    const startValue = new Date(start).valueOf();
    const endValue = new Date(end).valueOf();

    const diff = Math.abs(endValue - startValue) / 86400000;

    const year = Math.floor(diff / 365);

    const month = Math.floor((diff - year * 365) / 30);

    const date = diff - year * 365 - month * 30;

    const texts: Array<string> = [];

    if (year) texts.push(`${year}년`);
    if (month) texts.push(`${month}개월`);
    if (date || (!date && !year && !month)) texts.push(`${date}일`);

    return texts.join(' ');
}

export function onErrorImg(e: React.SyntheticEvent<HTMLImageElement>, path: string) {
    e.currentTarget.src = path;
}

export function getAuthErrorMessage(name: string) {
    return `${name}에 대한 접근권한이 없습니다.`;
}

export const encNumber = (_id: string) => {
    const id = _id.toString();

    const charArray =
        'kI_fvG_LzJ_xpdYV_j_oXNnz_cv_A_MH_lmqemgpXZubFBIhA_DM_e_Syky_fENygYoJxH_XW_HQx_xyRyY_NC_H_Psp_P_X_TZTXHwq_F_DXHSqHX_WZu_ZJ_C_svOwP_DRQ_q_WM_uAQ_j_kLrRotCz_b_s_C_EplRkLt_lr_Q_x_KXjoXvqc_yvhXjZ_H_U_YR_Eqdo_u_M_Qi_E_OXlKjsONzIhDTETxuSI_Smvm_UZDhm_xQDLH_jok_rg_nZHaqNoBC_G_UZt_plx_vXOGO_kwyHb_wTHnb_Oa_jT_eT_a_YU_L_t_yBqP_lzR_iro_u_Ww_FQUSqSqlvuvWx_rFoRDuCHTn_D_g_QHzGmKNonIZ_dOW_Ic_RausB_cJY_W_JmxKv_q_K_h_b_W_OHwdaSr_oJ_D_w_UgWSGAGPM_uVyi_fVrNafLIVjPqC_zq_FZrkv_h'.split(
            ''
        );
    const charArray2 =
        'ZtrgHoQX_JfCP_Bp_H_UwCG_Q_nyoKaRE_J_gKG_cNTRrBWyhs_lZ_oSjVWnlbJ_JruDx_qR_cFSWYTYvJn_MPcP_p_Ii_H_NuSW_zoycw_gusLm_yQ_OG_Nm_TvCdNxIGw_T_zniQFc_WfszszF_SbPsyR_EVWlo_BPWwQrL_uRLfd_yhQqtloEq_Fr_oxw_hJ_EtXDjg_PsRE_lY_A_JE_MYK_z_N_R_y_iwu_La_NL_QSA_crD_r_xs_LTDT_IrXqTU_m_Y_sg_dx_KTw_CNo_CznHhHBxiH_Hpv_tUZE_E_JELd_RqeKjezOjH_sgDGvXTrli_f_R_ZSVBh_Cht_A_O_BQ_wAB_qYHTd_ZY_gD_mJq_O_lGlV_YjGyXru_mfTMYP_epc_nhz_YG_kpgfhkCT_Y_p_aEBpdWtgbsb_eIdD_x_AO_EuSd_v'.split(
            ''
        );

    const array = id.split('');

    return array
        .map((item, index) => {
            const next = array[index + 1];

            if (next) {
                const value = parseInt(item + index, 10);
                return charArray[value % (charArray.length - 1)] + charArray2[value % (charArray2.length - 1)];
            }
            return charArray[+item] + charArray2[+index];
        })
        .join('');
};

export function ellipsis(text: string, max: number) {
    if (text.length > max) {
        return `${text.substring(0, max - 2)}...`;
    }

    return text;
}

export function focusForId(id: string) {
    const element = document.getElementById(id);

    if (element) {
        element.focus();
        return true;
    }
    return false;
}

export function amPmTimeTo24Format(time: string, amPm: AmPm) {
    if (amPm === 'pm') {
        const [hour, minute] = time.split(':');

        return `${Number(hour) + 12}:${minute}`;
    }

    return time;
}

export function AUTH_ROUTER_PATH(nm1: string, nm2: string, nm3: string) {
    if (nm1 === '대시보드') {
        if (nm2 === '대시보드') return '/csms';
    }

    if (nm1 === '인프라') {
        if (nm2 === '대시보드') return '/csms/infra';
        if (nm2 === '충전소 리스트') return '/csms/infra/station_list';
        if (nm2 === '충전소 상세') return '/csms/infra/station_list_detail';
        if (nm2 === '충전소 등록') return '/csms/infra/station_registration';
        if (nm2 === '충전기 리스트') return '/csms/infra/charger_list';
    }

    if (nm1 === '빌링') {
        if (nm2 === '대시보드') return '/csms/billing';
        if (nm2 === '결제') {
            if (nm3 === '결제 정보') return '/csms/billing/payment/payment_info';
        }
    }

    return '';
}

export function convertWattTokWh(value: number) {
    return !value ? '0' : (value / 1000).toLocaleString();
}

export function betweenNumber(x: number, min: number, max: number) {
    return x >= min && x <= max;
}

export function imageURLCheck(path: string) {
    if (path.startsWith('http://') || path.startsWith('https://') || path.startsWith('data:image/')) {
        return path;
    }

    return IMAGE_URL + path;
}

export function makeStatusColor(status: string) {
    switch (status) {
        case '이용가능': {
            return 'color_lightGreen';
        }
        case '이용중': {
            return 'color_skyBlue';
        }
        case '고장': {
            return 'color_yellow';
        }
        case '점검중': {
            return 'color_teal';
        }
        case '대기': {
            return 'color_sub';
        }
        default: {
            return '';
        }
    }
}

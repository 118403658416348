/* eslint-disable class-methods-use-this */
import axios from 'axios';
import { API_URL } from '../service/config';
import { LoginResponseUserModel } from '../service/model/auth/login-model';
import StorageManager from './storage-manager';

const sm = new StorageManager();

type Key = 'accessToken' | 'refreshToken';

const util = {
    getData: <T>(key: string): T | null => {
        if (sm.get('user-data').getValue() === null) return null;

        return sm.get('user-data').getObject(key) as T;
    },

    setData: (key: Key, value: string) => {
        const sm = new StorageManager();

        const origin = sm.get('user-data').getObject() as LoginResponseUserModel;

        origin[key] = value;

        new UserManager().login(origin);
    },
};

class UserManager {
    login(data: LoginResponseUserModel) {
        sm.set('user-data', data);
    }

    logout() {
        try {
            axios({
                url: `${API_URL}/user/logout`,
                method: 'DELETE',
                data: {
                    refreshToken: this.getRefreshToken(),
                },
            });
        } catch {
            //
        }

        sm.remove('user-data');
    }

    isLogin() {
        return sm.get('user-data').getValue() !== null;
    }

    isGuest() {
        return sm.get('user-data').getValue() === null;
    }

    getAccessToken() {
        return util.getData<string>('accessToken');
    }

    getRefreshToken() {
        return util.getData<string>('refreshToken');
    }

    getUserData() {
        return sm.get('user-data').getObject() as LoginResponseUserModel;
    }

    setAccessToken(accessToken: string) {
        return util.setData('accessToken', accessToken);
    }

    setRefreshToken(refreshToken: string) {
        return util.setData('refreshToken', refreshToken);
    }
}
export default UserManager;

import axios from 'axios';
import { API_URL } from '../service/config';
import AuthManager from '../shared/auth-manager';
import UserManager from '../shared/user-manager';

axios.defaults.baseURL = API_URL;

interface GetQueryModel {
    url: string;
    params?: any;
    sendAccessToken: boolean;
    isUserRequest?: boolean;
}

const getQuery = async <T>({ url, sendAccessToken, params, isUserRequest }: GetQueryModel): Promise<T> => {
    const promise = axios({
        url,
        method: 'GET',
        headers: sendAccessToken
            ? {
                  Authorization: `Bearer ${
                      isUserRequest ? new UserManager().getAccessToken() : new AuthManager().getAccessToken() ?? ''
                  }`,
              }
            : undefined,
        params,
    }).then((res) => res.data.data);

    return promise;
};

export default getQuery;

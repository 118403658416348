export const PATH = {
    COMPONENTS: '/components',
    INDEX2: '/index2',

    /* 일반회원 > 마이페이지 */
    MYPAGE: '/mypage',
    MYPAGE_EDIT: '/mypage/my_edit',
    CHARGING_CARD_CHANGE: '/mypage/charging_card_change',
    PAYMENT_CARD_CHANGE: '/mypage/payment_card_change',
    CHARGING_CARD_REG: '/mypage/charging_card_reg',

    /* 일반회원 > 로그인 및 아이디,비밀번호 찾기 */
    LOGIN_TYPE2: '/user/login',
    FIND_ID: '/user/find_id',
    FIND_PW: '/user/find_pw',

    /* 일반회원 > 회원가입 */
    JOIN: '/user/join',

    /* NICE 본인인증 결과 페이지 */
    NICE_SUCCESS: '/nice/success',
    NICE_ERROR: '/nice/error',

    /* 일반회원 > 회원가입 > 약관동의 */
    USER_TERMS: '/use_terms',

    /* 관리자 */
    LOGIN: '/csms/login', // 관리자 로그인

    /*  DASHBOARD */
    DASHBOARD: '/csms', // 메인 대시보드

    /* 인프라 */
    INFRA: '/csms/infra', // 인프라
    STATION_LIST: '/csms/infra/station_list', // 충전소 리스트
    STATION_LIST_DETAIL: '/csms/infra/station_list_detail', // 충전소 리스트 정보
    STATION_REGISTRATION: '/csms/infra/station_registration', // 충전소 등록
    STATION_MODIFY: '/csms/infra/station_modify', // 충전소 수정
    CHARGERLIST: '/csms/infra/charger_list', // 충전소 등록

    /* 빌링 */
    BILLING: '/csms/billing', // 빌링
    PAYMENT_INFO: '/csms/billing/payment/payment_info', // 결제 정보
    VERIFICATION_PAYMENT: '/csms/billing/payment/verification_payment_info', // 검증결제 정보
    PAYMENT_CANCEL_INFO: '/csms/billing/payment/payment_cancel_info', // 결제취소 정보
    POINT: '/csms/billing/point',
    RATE: '/csms/billing/rate',

    /* 회원 */
    MEMBER: '/csms/member', // 회원
    MEMBER_LIST: '/csms/member/general_member/member_list', // 회원 리스트
    MEMBER_INFO: '/csms/member/general_member/member_info', // 회원리스트 > 회원 정보
    MEMBER_REG: '/csms/member/general_member/member_reg', // 회원 등록
    MEMBER_MODIFY: '/csms/member/general_member/member_modify', // 회원 수정
    ACCESS_INFO: '/csms/member/general_member/access_info', // 접속 정보
    CARD_ISSUER: '/csms/member/general_member/card_issuer', // 회원카드 발급
    TERMS: '/csms/member/general_member/terms', // 약관
    TERMS_REG: '/csms/member/general_member/terms_reg', // 약관 등록
    TERMS_MODIFY: '/csms/member/general_member/terms_modify', // 약관 수정

    BUSINESS_LIST: '/csms/member/business/business_list', // 사업자 리스트
    BUSINESS_INFO: '/csms/member/business/business_info', // 사업자 정보
    BUSINESS_REG: '/csms/member/business/business_reg', // 사업자 등록
    BUSINESS_MODIFY: '/csms/member/business/business_modify', // 사업자 수정
    BUSINESS_BILLING: '/csms/member/business/business_billing', // 사업자 빌링

    MANAGER_LIST: '/csms/member/manager/manager_list', // 관제요원 리스트
    MANAGER_ACCESS_INFO: '/csms/member/manager/manager_access_info', // 접속정보
    MANAGER_LOG: '/csms/member/manager/manager_log', // 로그

    MY_INFO: '/csms/member/my_info',

    /* 운용 */
    OPERATION: '/csms/operation',
    POWER: '/csms/operation/power',
    COMMUNICATION: '/csms/operation/communication',

    FAILURE_INFO: '/csms/operation/failure/failure_info',
    CHECK_INFO: '/csms/operation/failure/check_info',
    FAILURE_DECLARE: '/csms/operation/failure/failure_declare',
    FAILURE_DECLARE_MODIFY: '/csms/operation/failure/failure_declare_modify',
    WORK_REPORT: '/csms/operation/failure/work_report',
    WORK_REPORT_MODIFY: '/csms/operation/failure/work_report_modify',
    FIRMWARE: '/csms/operation/firmware',

    SET_PERMISSION: '/csms/operation/general/set_permission',
    REPORT: '/csms/operation/general/report',
    CAR_MODEL: '/csms/operation/general/car_model',
    CHARGER_MODEL: '/csms/operation/general/charger_model',
    SET_MEMBER_CARD: '/csms/operation/general/set_member_card',
    CHARGE_UNIT_PRICE: '/csms/operation/general/charge_unit_price',

    MEMBER_INQUIRY: '/csms/operation/service_center/member_inquiry',
    CONSULTANT_LIST: '/csms/operation/service_center/consultant_list',
    CONSULTATION_LIST: '/csms/operation/service_center/consultation_list',
    CONSULTATION_REG: '/csms/operation/service_center/consultation_reg',

    OCPP_SCENARIO: '/csms/scenario',
};

export const ALLOW_IMAGE_EXTENSION = ['jpeg', 'png', 'jpg'];

export const ALLOW_IMAGE_EXTENSION2 = ['jpeg', 'png', 'jpg', 'svg'];

/**
 * 계약 서류 :  .pdf, .doc, .docx, .hwp
 */
export const ALLOW_FILE_CONTRACT_EXTENSION = ['pdf', 'doc', 'docx', 'hwp'];

/**
 * 관련 서류 : .pdf, .xlsx, .hwp, .zip
 */
export const ALLOW_FILE_ATTACH_EXTENSION = ['pdf', 'xlsx', 'hwp', 'zip'];

export const ONLY_NUMBER_REGEXP = /[^0-9]/gi;

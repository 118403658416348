export const API_URL = 'https://evc-api.nbplus.co.kr';
//export const API_URL = "http://localhost:33010";

export const IMAGE_URL = API_URL;

export const OCPP_URL = 'https://evc-ocpp.nbplus.co.kr';
//export const OCPP_URL = "http://localhost:3020:";

export const SOCKET_URL = 'https://evc-ocppsock.nbplus.co.kr';
//export const SOCKET_URL = "http://localhost:3020";

export const COMMON_ERROR_MSG = '알 수 없는 오류가 발생했습니다.';

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@charset "UTF-8";
/*===========================================================================
	'폰트'
============================================================================*/
/*===========================================================================
	드래그 금지
============================================================================*/
/*===========================================================================
	카드 공통
============================================================================*/
`, "",{"version":3,"sources":["webpack://./src/style/_mixin.scss"],"names":[],"mappings":"AAAA,gBAAgB;AAAhB;;6EAG6E;AAwC7E;;6EArC6E;AAiD7E;;6EA9C6E","sourcesContent":["/*===========================================================================\n\t'폰트'\n============================================================================*/\n@mixin ff_b {\n    font-family: 'pretendard-bold';\n}\n@mixin ff_m {\n    font-family: 'pretendard-medium';\n}\n@mixin ff_sm {\n    font-family: 'pretendard-semibold';\n}\n@mixin ff_r {\n    font-family: 'pretendard-regular';\n}\n@mixin ff_l {\n    font-family: 'pretendard-light';\n}\n\n@mixin font($size, $weight, $scale: null) {\n    @if ($scale) {\n        font-size: #{$size * $scale}px;\n    } @else {\n        font-size: $size + px;\n    }\n\n    @if $weight == 'b' {\n        font-family: 'pretendard-bold';\n    }\n    @if $weight == 'm' {\n        font-family: 'pretendard-medium';\n    }\n    @if $weight == 'sm' {\n        font-family: 'pretendard-semibold';\n    }\n    @if $weight == 'r' {\n        font-family: 'pretendard-regular';\n    }\n    @if $weight == 'l' {\n        font-family: 'pretendard-light';\n    }\n}\n\n/*===========================================================================\n\t드래그 금지\n============================================================================*/\n@mixin no_drag_default {\n    user-select: none;\n}\n\n@mixin no_drag_point {\n    user-select: none;\n    cursor: pointer;\n}\n\n/*===========================================================================\n\t카드 공통\n============================================================================*/\n@mixin card_form {\n    background-color: var(--cardBackground);\n    border-radius: 8px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
